var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"}},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"envio_inicial","sortDesc":true,"pdf":"","csv":"","excel":""},scopedSlots:_vm._u([{key:"envio_inicial",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.renderData(col))+" ")]}},{key:"status_processamento",fn:function(ref){
var col = ref.col;
return [_c('v-chip',{attrs:{"color":_vm.status[col].class,"small":""}},[_vm._v(" "+_vm._s(_vm.status[col].text)+" ")])]}},{key:"ultima_alteracao_status",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.renderData(col))+" ")]}},{key:"idprocescarga",fn:function(ref){
var row = ref.row;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('FormButton',{attrs:{"icon":""},on:{"click":function($event){return _vm.onClick(row)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("fa-info-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalhes")])])]}}])})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }