<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="envio_inicial"
        :sortDesc="true"
        ref="datatable"
        pdf
        csv
        excel
      >
        <template v-slot:envio_inicial="{ col }">
          {{ renderData(col) }}
        </template>
        <template v-slot:status_processamento="{ col }">
          <v-chip :color="status[col].class" small>
            {{ status[col].text }}
          </v-chip>
        </template>
        <template v-slot:ultima_alteracao_status="{ col }">
          {{ renderData(col) }}
        </template>
        <template v-slot:idprocescarga="{ row }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <FormButton icon @click="onClick(row)">
                <v-icon v-bind="attrs" v-on="on" size="20"
                  >fa-info-circle</v-icon
                >
              </FormButton>
            </template>
            <span>Detalhes</span>
          </v-tooltip>
        </template>
      </DataTable>
    </Panel>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import * as moment from 'moment'

export default {
  name: 'CargaPrecoTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'ID', clickable: false, value: 'idprocessamento' },
      {
        text: 'Data de envio',
        clickable: false,
        value: 'envio_inicial',
        type: 'datetime'
      },
      {
        text: 'Status',
        clickable: false,
        value: 'status_processamento',
        align: 'center'
      },
      {
        text: 'Último status',
        clickable: false,
        value: 'ultima_alteracao_status',
        type: 'datetime'
      },
      {
        text: 'Quant. Itens',
        clickable: false,
        value: 'length_carga',
        align: 'center'
      },
      {
        text: 'Opções',
        value: 'idprocescarga',
        clickable: false,
        align: 'center'
      }
    ],
    status: {
      nao_iniciado: { text: 'Não Iniciado', class: 'info' },
      iniciado: { text: 'Iniciado', class: 'info' },
      abortado: { text: 'Abortado', class: 'danger' },
      concluido: { text: 'Concluído', class: 'success' }
    },
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v2/integracao-carga-preco/fornecedor/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    onClick(item) {
      this.$router.push(`integracao-carga-preco/${item.idprocessamento}`)
    },
    renderData(col) {
      moment.locale('pt-br')
      var a = moment(col * 1000)
      return a.format('L HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped></style>
